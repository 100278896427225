/* ベーススタイルとボックスサイジングのグローバル適用 */
*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
  padding: 10px; /* 余白を少し調整 */
  width: 100%; /* コンテナの幅を指定 */
  max-width: 100%; /* 最大幅を画面幅に制限 */
  overflow: hidden; /* はみ出した内容は非表示 */
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

select, button {
  font-size: 1rem;
  margin-bottom: 10px;
}

.button {
  padding: 10px 20px;
  background-color: #61dafb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #4aa9c7;
}

/* ライン共有ボタンとアイコンのスタイル */
.line-share-button {
  display: inline-block;
  margin: 10px;
}

.line-icon {
  width: 50px;
  height: auto;
}

/* エラーメッセージのスタイリング */
.error {
  color: red;
  margin-top: 10px;
}

.banner {
  background-color: #d2b48c; 
  color: white; /* 文字色を白に */
  text-align: center; /* テキストを中央揃えに */
  padding: 5px 0; /* 上下にパディングを追加 */
  font-size: 3rem; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  margin-bottom: 20px; /* 下にマージンを追加 */
}

/* 画像のスタイリング */
.dog-image, .child-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 10px auto;
}

.child-image {
  max-width: 80%; /* 子供の画像を80%の最大幅で */
}

/* グリッドセクションとサインセクションのスタイリング */
.signs-section, .sign-section {
  display: grid;
}

.signs-section {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

.sign-section {
  grid-template-rows: auto 1fr auto;
  align-items: start;
}

.child-section {
  text-align: center;
  margin-top: 20px;
}

/* メディアクエリでのレスポンシブデザイン */
@media (max-width: 768px) {
  .banner {
    font-size: 1.5rem; /* スマホ用にフォントサイズを小さくする */
    padding: 15px 0; /* スマホ用にパディングを調整 */
  }
  .signs-section {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
}

@media (min-width: 769px) {
  .signs-section {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }
}
.reload-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reload-button:hover {
  background-color: #0056b3;
}
.english-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #007BFF;
  color: white;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}

.english-button:hover {
  background-color: #0056b3;
}